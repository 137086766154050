import React, { useState } from "react"
import styled from 'styled-components'

const InlineForm = () => {

  const [formData, setFormData] = useState({name: null, message: null, email: null});
  const [formSubmitted, setFormSubmitted] = useState(false);


  const handleFormFieldChange = e => {
    const {name, value} = e.target;
    setFormData(prevState => (
      {...prevState, [name]: value}
    ));
  };

  const handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-me", ...formData})
    })
      .then(result => {
        setFormSubmitted(true);
        const inlineFormRoot = document.getElementById('inlineFormRoot');
        inlineFormRoot.scrollIntoView(true);
      })
      .catch( error => {
      })
    e.preventDefault();
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return(
    <InlineFormStyles id={"inlineFormRoot"} style={{scrollBehavior: 'smooth'}}>
      {
        formSubmitted
          ?
          <div className={'form-container'}>
            <div className={'typography-main form-text'}>Thanks! Here's what you sent.</div>
            <div className={'separator'}/>
            <div className={'typography-main form-text'}>Hi Alan, my name is {formData['name']},</div>
            <div className={'typography-main form-text'}>and I'd like to talk to you about {formData['message']}</div>
            <div className={'typography-main form-text'}>You can reach me at {formData['email']}.</div>
          </div>
          :
          <div>
            <form className={'form-container'} name={'contact-me'} method={'POST'}  data-netlify={true} onSubmit={handleSubmit}>
              <div className={'typography-main'}>Submit below to get in touch!</div>
              <div className={'separator'}/>
              <input type="hidden" name={'form-name'} value={'contact-me'}/>
              <div className={'typography-main form-text'}>Hi Alan, my name is</div>
              <span><input type="text" placeholder={'Name'} name={'name'} required onChange={handleFormFieldChange}/><span className={'typography-main'}>,</span></span>
              <div className={'typography-main form-text'}>and I'd like to talk to you about</div>
              <textarea
                className={formData['message'] ? 'areaDirty' : ''}
                onChange={handleFormFieldChange}
                name="message"
                id="message"
                cols='20'
                rows="10"
                placeholder={'Message'}
                required
              />
              <div className={'typography-main form-text'}>You can reach me at</div>
              <span><input type="email" placeholder={'Email'} name={'email'} onChange={handleFormFieldChange} required /><span className={'typography-main'}>.</span></span>
              <button>Send</button>
            </form>
          </div>
      }
    </InlineFormStyles>
  );
}

const InlineFormStyles = styled.div`
  padding-top: 4vh;
  
  .form-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .separator {
    height: .5vh;
    margin: 4vh 0;
    background:
      linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100vw;
  }
 
    input[type='text'], input[type='email'] {
      margin: 4vh auto;
      font-family: Fira Code, Arial, sans-serif;
      font-size: 2rem;
      padding-left: 1vw;
      width: 80vw;
      height: 3rem;
      border: 2px solid black;
      border-radius: 3px;
      background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
      ) center bottom 10px /calc(100% - 20px) 2px no-repeat;   
      
      @media screen and (min-width: 768px) {
        width: 30vw;
      }
    }
    
    input[type='text']:focus, input[type='email']:focus {
      outline: none;
    }
    
    button {
      width: 80vw;
      height: 10vh;
      background-color: #C4C4C4;
      margin: 4vh 4vw;
      border: none;
      border-radius: 3px;
      box-shadow: 0 0 12px 10px rgba(0, 0, 0, 0.44);
      font-size: 2rem;
      font-weight: 120;
      cursor: pointer;
    }
    
    textarea {
      margin: 4vh auto;
      padding: 1vh 0 0 1vw;
      width: 80vw;
      height: 50vh;
      font-family: Fira Code, Arial, sans-serif;
      font-size: 2rem;
      border: 2px solid black;
      border-radius: 3px;
      transition: background-position .5s linear;
      line-height: calc(2rem + 1px);
      background: #BFBAAD;
      background: linear-gradient(
        to right,
        #BFBAAD 1vw,
        transparent 1vw 
      ), linear-gradient(
        135deg,
        transparent 50%,
        rgb(191,186,173) 70%,
        rgb(191,186,173) 100%
      ), repeating-linear-gradient(
        to bottom,
        rgba(191, 186, 173, 0),
        rgba(191, 186, 173, 0) 2rem,
        rgba(0, 0, 0, 1) 2rem,
        rgba(0, 0, 0, 1) calc(2rem + 1px),
        rgba(191, 186, 173, 0) calc(2rem + 1px)
      ) 0 1vh no-repeat;
    }
 
    textarea:focus {
      outline: none;
      background: none;
    }
 
    .areaDirty {
      background: none;
    }
 
    input[type='submit']:focus {
      outline: none;
    }
 
`;

export default InlineForm