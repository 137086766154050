import React from "react"
import Layout from "../components/Layout/Layout"
import LayoutStyles from "../components/LayoutStyles/LayoutStyles"
import { Helmet } from "react-helmet"
import StyledBackgroundImageFull from "../components/BackgroundImageFull/BackgroundImageFull"
import SectionTransition from "../components/SectionTransition/SectionTransition"
import ScrollNudge from "../components/ScrollNudge/ScrollNudge"
import InlineForm from "../components/InlineForm/InlineForm"

const Contact = () => {
  return (
    <div>
      <Layout>
        <Helmet>
          <title>Contact | Alan Henson - Freelance Full-Stack Engineer</title>
        </Helmet>
        <LayoutStyles>
          <StyledBackgroundImageFull imgName={'fireTower'}/>
          <div className={'above-fold-container'}>
            <div className="gradient-billboard">
              <div style={{marginTop: '5vh'}} className={'typography-main'}>Contact Me</div>
            </div>
            <SectionTransition from={'rgba(0, 0, 0, 0)'} to={'#BFBAAD'} static>
              <ScrollNudge color={'#000'}/>
            </SectionTransition>
          </div>
          <div style={{background: '#BFBAAD'}}>
            <InlineForm/>
          </div>
          <SectionTransition from={'#BFBAAD'} to={'#5f6260'} static/>
        </LayoutStyles>
      </Layout>
    </div>
  )
}

export default Contact